import React, { ReactNode } from "react";
import VariationType from "../ui/VariationType";
import NumberFormat from "react-number-format";
import IsMatrix from "../ui/IsMatrix";
import moment from "moment";
import MetaAnalysis from "../../types/models/MetaAnalysis";
import { Matrix } from "../../types/models/Matrix";

type MetaCalculationsRowsPartialTypedProps = {
  within_data?: MetaAnalysis;
  between_data?: MetaAnalysis;
  matrix: Matrix
};

function MetaCalculationsRowsPartialTyped({
  within_data,
  between_data,
  matrix
}: MetaCalculationsRowsPartialTypedProps) {
  
  let rows: Array<any> = []

  create_row(rows, within_data)
  create_row(rows, between_data)

  function create_row(rows : Array<any>, meta_analysis? : MetaAnalysis){
      rows.push(
        <tr key={meta_analysis?.median}>
          <td>
            <IsMatrix matrix={matrix} />
          </td>
          <td>
            <VariationType vartype={meta_analysis?.var_type} />
          </td>
          <td className="font-monospace">
            {meta_analysis?.median == 0 ? (
              "NA"
            ) : (
                            <NumberFormat
                value={meta_analysis?.median}
                displayType={"text"}
                decimalScale={1}
              />
            )}
            {" "}
          </td>
          <td className="font-monospace">
            <NumberFormat
              value={meta_analysis?.lower}
              displayType={"text"}
              decimalScale={1}
            />
          </td>
          <td className="font-monospace">
            <NumberFormat
              value={meta_analysis?.upper}
              displayType={"text"}
              decimalScale={1}
            />
          </td>
          <td>{meta_analysis?.comments}</td>
          <td>{moment(meta_analysis?.updated_at).format("D/M/YYYY")}</td>
        </tr>
      );
  }



  return <React.Fragment>{rows}</React.Fragment>;
}

export default MetaCalculationsRowsPartialTyped;
