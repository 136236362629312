function extractArraysFromMetaAnalysis(reference_array:number[][]) : number[]{
  let result = reference_array!.map((a) => {
    return a;
  });

  let result2: any[] =[];

  // if (Array.isArray(result)) {
  //   // result2 = result.map((a) => a.map((b) => parseInt(b)));
  //   result2 = result.map(processItem)
  // }

  // function processItem(item: any[]) {

  //   if (Array.isArray(item)) {
  //     item.map(createInt);
  //   }
    
  // }

  // function createInt(item: string){
  //   parseInt(item);
  // }

    if (Array.isArray(result)) {
      // result2 = result.map((a) => a.map((b) => parseInt(b)));
      result2 = result.flat(1)
    }
  
  var x: number[] = [];
  var merged = x.concat.apply([], result2);
  let uniqueItems = [...new Set(merged)];
  return uniqueItems
}

export default extractArraysFromMetaAnalysis;