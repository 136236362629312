import React from "react";
import { Matrix } from "../../types/models/Matrix";

type MatrixProps = {
  matrix: Matrix;
};

function MatrixDisplay({ matrix }: MatrixProps): JSX.Element {
  if (matrix === undefined)
    return (
      <b>
        <p className="text-warning">No Matrix</p>
      </b>
    );
  else {
    return (
      <b>
        <p className="text-success">
          {matrix.matrix_expansion} {matrix.matrix_explanation}
        </p>
      </b>
    );
  }
}

export default MatrixDisplay;
